import { useAppSelector } from "../../hooks/hooks.ts";
import { Suspense, useEffect, useState } from "react";
import { Perf, PerfHeadless, usePerf } from "r3f-perf";
import { Canvas, useThree } from "@react-three/fiber";

import GlobalMap from "./R3FMapComponents/GlobalMap.tsx";
import LocalMap from "./R3FMapComponents/LocalMap.tsx";

import { useProgress } from "@react-three/drei";
import LoadingScreen from "../loading_screen/LoadingScreen.tsx";
import InteriorBiosphere from "./R3FMapComponents/LocalComponents/T2Buildings/Biospheres/InteriorBiosphere.tsx";

const R3FMap = ({ ...props }) => {
  const studentState = useAppSelector((state) => state.student);

  const [isLocalMap, setIsLocalMap] = useState(false);

  useEffect(() => {
    setIsLocalMap(props.localMap);
  }, [props.localMap]);

  const CameraUpdater = ({ localMap }: { localMap: boolean }) => {
    const { camera } = useThree();

    useEffect(() => {
      if (camera && isLocalMap !== localMap) {
        if (localMap) {
          camera.position.set(10, 15, -10);
          camera.rotation.set(-Math.PI / 2, 0, 0);
        } else {
          camera.position.set(20, 15, 15);
          camera.rotation.set(0, 0, 0);
        }
      }
    }, [localMap]);

    return null;
  };

  // const PerfHook = () => {
  //   // getPerf() is also available for non-reactive way
  //   const [gl, log, getReport] = usePerf((s) => s[(s.gl, s.log, s.getReport)]);
  //   console.log(gl, log, getReport());
  //   return <PerfHeadless />;
  // };

  const Loader = () => {
    const { active, progress, errors, item, loaded, total } = useProgress();
    return <LoadingScreen percentage={progress} />;
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Canvas
          shadows
          // frameloop={props.renderAnimation() ? "always" : "demand"}
          gl={{
            powerPreference: "default",
            antialias: false,
          }}
          camera={{
            fov: 45,
            near: 1,
            far: 150,
            position: props.localMap ? [10, 15, -10] : [20, 15, 15],
            rotation: props.localMap ? [-Math.PI / 2, 0, 0] : [0, 0, 0],
          }}
        >
          <Perf />
          <CameraUpdater localMap={props.localMap} />
          {props.localMap && props.biosphere === null && (
            <LocalMap
              IntMult={
                props.isChallenge1Open() && studentState.challenge1Step < 24
                  ? 0.1
                  : 1
              }
              studentId={props.localMapStudentId}
              type={props.localMapType}
              maxDist={28}
              openLab={props.labHandler}
              openEnergyLab={props.energyLabHandler}
              toggleMap={props.localHandler}
              openBiosphere={props.biosphereHandler}
              openWorkshop={props.workshopHandler}
              openHome={props.homeHandler}
              openShuttle={props.shuttleHandler}
              openExplorersHut={props.explorersHutHandler}
              openChallenges={props.challengesHandler}
              houseMains={(position: number) =>
                props.toggleHouseMainsOpen(position)
              }
              renderShadow={() => props.renderShadow()}
              renderAnimation={() => props.renderAnimation()}
              tutorial={props.tutorial}
            />
          )}
          {!props.localMap && props.biosphere === null && (
            <GlobalMap
              maxDist={25}
              toggleMap={(studentId: number, plotId: number) =>
                props.localHandler(studentId, plotId)
              }
              classCO2={props.classCO2}
              renderShadow={() => props.renderShadow()}
              renderAnimation={() => props.renderAnimation()}
            />
          )}
          {props.biosphere !== null && (
            <InteriorBiosphere
              openBiosphere={props.biosphereHandler}
              renderShadow={() => props.renderShadow()}
              renderAnimation={() => props.renderAnimation()}
            />
          )}
        </Canvas>
      </Suspense>
    </>
  );
};

export default R3FMap;
