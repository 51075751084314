import env from "../../config/env.json";
import { ImageType, AnimationType } from "../content/unit";
import { BiomeNameType } from "./plots";

export interface GLTFType {
  id: string;
  url: string;
  geometry: string[];
  material: string[];
  animation: string[];
}

export interface AssetType {
  id: string;
  position: number[];
  rotation: number[];
  scale: number[];
  local_global: string;
  biome: BiomeNameType;
  gltf_id: string;
  gltf: GLTFType;
}

export interface HouseUpgradeType {
  id: string;
  name: string;
  description: string;
  level_power: number;
  purchase_power: number;
  starting_population: number;
  upgrade_population: number;
  max_level: number;
  type: number;
  position: number;
  asset_id: string;
  image_id: string;
  image: ImageType;
  asset: AssetType;
  animation: AnimationType[];
}

export const GetAllHouseUpgradesAPI = async (): Promise<HouseUpgradeType[]> => {
  var req = await fetch(env.backend_host + "/house_upgrade/all", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
    mode: "cors",
    credentials: "include",
  });
  var data = await req.json();

  data = data || null;
  return data;
};
