import "./ScenarioMenu.css";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks.ts";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useMemo, MouseEvent } from "react";
import { LazyMotion, m } from "framer-motion";
import BackArrow from "../../../assets/exercises/back_arrow.tsx";
import BackCross from "../../../assets/professor/close.tsx";
import tick from "../../../assets/professor/ok.svg";
import bin from "../../../assets/professor/bin.svg";
import placeholderExp from "../../../assets/professor/placeholder_exp.png";
import placeholderInv from "../../../assets/professor/placeholder_inv.png";
import GroupAddButton from "../../../assets/professor/studentAddGroup.tsx";
import OnePersonIcon from "../../../assets/professor/one_person_icon.svg";
import edit from "../../../assets/professor/edit.svg";
import Loading from "../../loading_screen/LoadingDots.tsx";
import { simpleGlobalCompetences } from "../../../constants.ts";
import { Spinner } from "@chakra-ui/react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Spacer,
  position,
  extendTheme,
  defineStyleConfig,
  Checkbox,
  MenuGroup,
  Tooltip,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import ScenarioEditMenu from "./ScenarioEditMenu.tsx";
import {
  GetStudentCoursePointsAPI,
  GetStudentScenarioPointsAPI,
  StudentCoursePoints,
  StudentScenarioPoints,
} from "../../../_newapios/progress/student_course_points.ts";

import {
  GetCourseEvaluationCriteriaAPI,
  GetCourseSpecificCompetencesAPI,
  CourseType,
} from "../../../_newapios/content/course.ts";
import _ from "lodash";

import {
  EvaluationCriterionType,
  SpecificCompetenceType,
  GetUnitAPI,
  GetUnitScenariosAPI,
  CloneUnitAPI,
  ModifiedUnitType,
  ScenarioType,
  UnitType,
  AllBundlesofUnitAPI,
} from "../../../_newapios/content/unit.ts";

import {
  AllBundlesofScenarioAPI,
  CloneScenarioAPI,
  GetScenarioAPI,
  ModifiedScenarioType,
} from "../../../_newapios/content/scenario.ts";

import { RegulationType } from "../../../_newapios/content/regulation.ts";
import ActivityProgress from "../menus/activities_submenus/ActivityProgress.tsx";
import {
  menu,
  setDeleteOnExit,
  submenu,
  updateSelectedBundle,
  updateSelectedScenario,
} from "../../../reducers/teacherSlice.ts";
import {
  globalCompetencesBooleanArray,
  prettifyCourseAcronyms,
  specificCompetencesBooleanArray,
} from "../../../utils/competences.ts";
import { BundleType } from "../../../_newapios/user/class.ts";
import {
  CreateBundleAPI,
  DeleteBundleAPI,
  UpdateBundleAPI,
} from "../../../_newapios/content/bundle.ts";
import {
  GetResearchAPI,
  ResearchType,
} from "../../../_newapios/content/research.ts";
import {
  GetExperimentAPI,
  ExperimentType,
} from "../../../_newapios/content/experiment.ts";
import { chooseLanguageFromDB } from "../../../utils/content.ts";
import {
  changeX,
  setExperiment,
  setResearch,
  setSelectedCourseId,
} from "../../../reducers/mainSlice.ts";
import close from "../../../assets/student/close_user_menu.svg";
import Alert from "../../alerts/Alert.tsx";
import { GetAllGroupsAPI, GroupType } from "../../../_newapios/user/group.ts";
import { StudentType } from "../../../_newapios/user/student.ts";
import { setGroupsFunc, studentsAreGroup } from "../../../utils/group.ts";
import { toast } from "../../../index.tsx";

const ScenarioMenu = ({ ...props }) => {
  const { t, i18n } = useTranslation(["common", "evaluation"]);
  const loginState = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();

  const mainState = useAppSelector((state) => state.main);
  const teacherState = useAppSelector((state) => state.teacher);

  const regulations = mainState.regulations;
  const globalCompetences = mainState.globalCompetences;
  const students = mainState.students;

  const [loading, setLoading] = useState(true);

  const [loadingBundles, setLoadingBundles] = useState<boolean>(true);
  const [loadingScenario, setLoadingScenario] = useState<boolean>(true);

  const scenario = teacherState.selectedScenario;
  const [copiedScenario, setCopiedScenario] =
    useState<ModifiedScenarioType | null>(null);

  const [showHoverText, setShowHoverText] = useState("none");
  const [showActivityProgress, setShowActivityProgress] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000)
  );
  const bundle = teacherState.selectedBundle;
  const [scenarioSubject, setScenarioSubject] = useState<CourseType>();
  const [scenarioRegulation, setScenarioRegulation] =
    useState<RegulationType>();

  const [dateError, setDateError] = useState<string | null>(null);
  const [groups, setGroups] = useState<GroupType[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<GroupType | null>(null); // groupId from which we want to delete the student
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);

  const isToastDisabled = bundle && bundle?.teacher_id !== loginState.id;

  const allStudents = [{ value: 0, label: t("all_students") }];

  const [scenarioPoints, setScenarioPoints] = useState<StudentCoursePoints>({});

  const [evaluationCriteria, setEvaluationCriteria] = useState<
    EvaluationCriterionType[]
  >([]);

  const [specificCompetences, setSpecificCompetences] = useState<
    SpecificCompetenceType[]
  >([]);

  const [deleteMenu, setDeleteMenu] = useState<BundleType | null>(null);
  const [duplicateMenu, setDuplicateMenu] = useState<string | null>(null);
  const [changeBundle, setChangeBundle] = useState<BundleType | null>(null);

  const [alertScenarioAlreadyCopied, setAlertScenarioAlreadyCopied] =
    useState(false);
  const [disableCheckboxes, setDisableCheckboxes] = useState<boolean>(false);
  const [alertChooseGroup, setAlertChooseGroup] = useState(false);

  const [allBundlesofScenario, setAllBundlesofScenario] = useState<
    BundleType[] | null
  >([]);

  const [popUpAddBundle, setPopUpAddBundle] = useState<boolean>(false);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  useEffect(() => {
    if (bundle !== undefined && !_.isEmpty(bundle)) {
      setStartDate(new Date(bundle.start_date));
      setEndDate(new Date(bundle.end_date));
      if (bundle.students !== undefined) {
        setSelectedStudents(bundle.students.map((student) => student.id));
      }
    } else {
      setStartDate(new Date());
      setEndDate(new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000));
      const allStudentIds: string[] = students.map(
        (student: StudentType) => student.id
      );
      const bundleWithAllStudents = allBundlesofScenario?.find((bun) => {
        const bundleStudentIds = bun.students?.map((student) => student.id);
        if (!bundleStudentIds) return false;
        return arraysEqual(bundleStudentIds, allStudentIds);
      });

      if (bundleWithAllStudents !== undefined) {
        setSelectedStudents([]);
      } else {
        setSelectedStudents(students.map((student: StudentType) => student.id));
      }
    }
  }, [bundle, allBundlesofScenario]);

  useEffect(() => {
    const newGroup = studentsAreGroup(groups, selectedStudents);
    setSelectedGroup(newGroup);
  }, [selectedStudents, groups]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    const fetchBundles = async () => {
      if (scenario === null) {
        return;
      }
      if (!scenario.id || !teacherState.selectedClass?.id) {
        return;
      }
      setLoadingBundles(true);
      const bundles = await AllBundlesofScenarioAPI(
        scenario.id,
        teacherState.selectedClass?.id
      );

      setAllBundlesofScenario(bundles);
      setLoadingBundles(false);
    };

    fetchBundles();
  }, [scenario, teacherState.selectedClass]);

  useEffect(() => {
    const fetchScenarioPoints = async () => {
      if (scenario === null) {
        return;
      }
      if (!scenario.id || !teacherState.selectedClass?.id) {
        return;
      }
      setLoadingScenario(true);
      await loadScenarioPoints(scenario);

      console.log("loading scenario points done");
      setLoadingScenario(false);
    };

    fetchScenarioPoints();
  }, [scenario, teacherState.selectedClass]);

  useEffect(() => {
    setLoading(true);
    var promises = [
      setScenarioStuff(scenario),
      GetAllGroupsAPI(teacherState.selectedClass?.id || "").then((data) =>
        setGroupsFunc(
          students,
          teacherState.selectedClass?.id || "",
          data,
          setGroups
        )
      ),
    ];
    Promise.all(promises).then(() => {
      console.log("loading done");
      setLoading(false);
    });
  }, [scenario, mainState.researchExercises, mainState.experimentExercises]);

  useEffect(() => {
    loadEvaluationCriteria();
    loadSpecificCompetences();
  }, [scenario?.course_id]);

  if (scenario === null) {
    return <></>;
  }

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const saveBundleHandler = () => {
    if (teacherState.selectedClass === null) {
      return;
    }
    if (startDate.getTime() > endDate.getTime()) {
      setDateError("end_date_before_start_date");
      return;
    }
    if (endDate.getTime() < new Date().getTime()) {
      setDateError("end_date_before_today");
      return;
    }

    if (selectedGroup === null || selectedStudents.length === 0) {
      alertChooseGroupHandler();
    }

    const bundleStudents = students.filter((student) =>
      selectedStudents.includes(student.id)
    );

    const newBundle: BundleType = {
      id: "",
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      weighing: 1,
      scenario_id: scenario.id,
      class_id: teacherState.selectedClass.id,
      students: bundleStudents,
      teacher_id: loginState.id,
    };

    if (bundle === null) {
      CreateBundleAPI(newBundle).then((result) => {
        dispatch(menu("menus"));
        dispatch(submenu("activities"));
      });
      return;
    }
    newBundle.id = bundle.id;
    UpdateBundleAPI(newBundle).then((result) => {
      dispatch(menu("menus"));
      dispatch(submenu("activities"));
    });
  };

  const deleteBundleHandler = async () => {
    var newBundle = bundle;
    if (newBundle === null) return;
    await DeleteBundleAPI(newBundle.id).then((result) => {
      dispatch(menu("menus"));
      dispatch(submenu("activities"));
    });
  };

  const researchHandler = async (event: any, scenario: ScenarioType) => {
    event.stopPropagation();
    const researchId = scenario.research.id;
    setLoading(true);
    const research = await GetResearchAPI(researchId);
    dispatch(setResearch(research));
    dispatch(setSelectedCourseId(scenario.course_id));
  };

  const experimentHandler = async (event: any, scenario: ScenarioType) => {
    event.stopPropagation();
    const experimentId = scenario.experiment.id;
    dispatch(changeX(Math.random()));
    setLoading(true);
    const experiment = await GetExperimentAPI(experimentId);
    dispatch(setExperiment(experiment));
    dispatch(setSelectedCourseId(scenario.course_id));
  };

  const arraysEqual = (a: any[], b: any[]) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    const sortedA = [...a].sort();
    const sortedB = [...b].sort();

    for (var i = 0; i < sortedA.length; ++i) {
      if (sortedA[i] !== sortedB[i]) return false;
    }
    return true;
  };

  const groupHandler = (event: any, group: GroupType) => {
    event.preventDefault();
    if (selectedGroup === group) {
      setSelectedGroup(null);
      setSelectedStudents([]);
      setDisableCheckboxes(false);
      return;
    }
    setSelectedGroup(group);
    setSelectedStudents(group.students.map((student) => student.id));
  };

  const studentHandler = (event: any, student: StudentType) => {
    event.preventDefault();

    if (selectedStudents.includes(student.id)) {
      const newSelectedStudents = selectedStudents.filter(
        (s) => s !== student.id
      );
      const bundleWithSameStudents = allBundlesofScenario?.find((bun) => {
        const bundleStudentIds = bun.students?.map((s) => s.id);
        if (!bundleStudentIds) return false;
        return arraysEqual(bundleStudentIds, newSelectedStudents);
      });
      if (bundleWithSameStudents !== undefined) {
        setSelectedStudents([...selectedStudents]);
      } else {
        setSelectedStudents(newSelectedStudents);
      }
    } else {
      const newSelectedStudents = [...selectedStudents, student.id];
      const bundleWithSameStudents = allBundlesofScenario?.find((bun) => {
        const bundleStudentIds = bun.students?.map((s) => s.id);
        if (!bundleStudentIds) return false;
        return arraysEqual(bundleStudentIds, newSelectedStudents);
      });

      if (bundleWithSameStudents !== undefined) {
        setSelectedStudents([...selectedStudents]);
      } else {
        setSelectedStudents(newSelectedStudents);
      }
    }
    if (selectedGroup !== null) {
      setSelectedGroup(null);
    }
  };

  const alertScenarioCopiedHandler = () => {
    setAlertScenarioAlreadyCopied(!alertScenarioAlreadyCopied);
  };

  const alertChooseGroupHandler = () => {
    setAlertChooseGroup(!alertChooseGroup);
  };

  const cloneScenarioHandler = async (scenario_id: string) => {
    const newScenario = await CloneScenarioAPI(scenario_id);

    if (newScenario === null) {
      alertScenarioCopiedHandler();
      return;
    }

    dispatch(updateSelectedScenario(newScenario));
    dispatch(updateSelectedBundle(null));
    dispatch(setDeleteOnExit(true));
    setDuplicateMenu(null);
    dispatch(submenu("scenario_edit"));
  };

  const dateErrorHandler = () => {
    setDateError(null);
  };

  const loadScenarioPoints = async (temp_scenario: ModifiedScenarioType) => {
    let newScenarioPoints = { ...scenarioPoints };
    for (let i = 0; i < mainState.students.length; i++) {
      const studentId = mainState.students[i].id;
      const scenarioId = temp_scenario.id;
      const points = await GetStudentScenarioPointsAPI(studentId, scenarioId);
      if (points !== null) {
        if (newScenarioPoints[studentId] === undefined) {
          newScenarioPoints[studentId] = 0;
        }
        newScenarioPoints[studentId] = points;
      }
    }
    setScenarioPoints(newScenarioPoints);
  };

  const loadEvaluationCriteria = async () => {
    setEvaluationCriteria(
      await GetCourseEvaluationCriteriaAPI(scenario?.course_id || "")
    );
  };
  const loadSpecificCompetences = async () => {
    setSpecificCompetences(
      await GetCourseSpecificCompetencesAPI(scenario?.course_id || "")
    );
  };

  const setScenarioStuff = (tempscenario: ModifiedScenarioType | null) => {
    if (tempscenario === null) {
      return;
    }
    setScenarioSubject(tempscenario.course);

    const tempRegulation = regulations.filter((regulation) => {
      return regulation.id === tempscenario.course.regulation_id;
    })[0];
    setScenarioRegulation(tempRegulation);
  };

  const deleteMenuHandler = (bundle: BundleType | null = null) => {
    if (isToastDisabled) {
      toast({
        title: t("access_denied"),
        description: t("not_authorized_delete"),
        status: "error",
        variant: "subtle",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setDeleteMenu(bundle);
  };

  const confirmChangesHandler = (bundle: BundleType | null = null) => {
    setPopUpAddBundle(false);
    if (isToastDisabled) {
      toast({
        title: t("access_denied"),
        description: t("not_authorized_change"),
        status: "error",
        variant: "subtle",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setChangeBundle(bundle);
  };

  const editScenarioHandler = () => {
    if (scenario?.owner_id === loginState.id) {
      dispatch(submenu("scenario_edit"));
    } else {
      setDuplicateMenu(scenario.id);
    }
  };

  const popUpAddBundleHandler = () => {
    setPopUpAddBundle(!popUpAddBundle);
  };

  const renderTop = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <div className="professor_activities_scenario_menu_top">
          <div className="professor_activities_scenario_menu_top_left left_container">
            <div className="professor_activities_scenario_menu_top_left_name_date">
              <m.div
                className="professor_activities_scenario_menu_top_left_name"
                whileHover={{
                  scale: 1.08,
                  textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
                onClick={() => {
                  if (showActivityProgress) {
                    setShowActivityProgress(false);
                  } else if (bundle !== null) {
                    dispatch(menu("menus"));
                    dispatch(submenu("activities"));
                  } else {
                    dispatch(submenu("marketplace"));
                  }
                }}
              >
                {showActivityProgress ? (
                  <BackCross />
                ) : (
                  <BackArrow color={"#000000"} size={"8vh"} />
                )}
                <div className="text_black_small_bold">
                  {scenario?.main_know_how}
                </div>
              </m.div>
            </div>
            <div
              className="professor_activities_scenario_menu_top_left_course"
              style={{ background: scenarioSubject?.color }}
            >
              <div className="center_container">
                <div className="text_white_very_small">
                  {t(scenarioSubject?.acronym + "_short", { ns: "evaluation" })}
                </div>
              </div>
            </div>
            <div className="professor_activities_scenario_menu_top_left_age">
              <div className="center_container">
                <div className="text_white_super_small">
                  {t(scenarioRegulation?.name || "", { ns: "evaluation" })}
                </div>
              </div>
            </div>
            <div className="professor_activities_scenario_menu_top_left_lang">
              <div className="center_container">
                <div className="text_white_very_small">
                  {t(chooseLanguageFromDB(scenario.language))}
                </div>
              </div>
            </div>
          </div>
          <div className="professor_activities_scenario_menu_top_right right_container">
            <div className="professor_activities_scenario_menu_top_right_type">
              <div className="text_black_very_small_bold">
                {t("research") + " | " + t("experiment")}
              </div>
            </div>
            <div className="professor_activities_scenario_menu_top_right_owner">
              <div className="text_black_very_small">
                {t("created_by") + " "}
                <b>{scenario.owner?.fullname}</b>
              </div>
            </div>
          </div>
        </div>
      </LazyMotion>
    );
  };

  const renderMiddle = () => {
    let menuButtonText: string | undefined;
    if (selectedGroup !== null) {
      menuButtonText = t(selectedGroup.name);
    } else if (selectedStudents.length === 1) {
      menuButtonText = students.find(
        (student) => student.id === selectedStudents[0]
      )?.username;
    } else if (selectedStudents.length > 1) {
      menuButtonText = `${selectedStudents.length} ${t(
        "students"
      ).toLowerCase()}`;
    }
    return (
      <div className="professor_activities_scenario_menu_mid">
        <LazyMotion features={loadFeatures}>
          <div
            style={{
              display: "grid",
              gridTemplateRows: "30% 70%",
              height: "7vh",
              width: "20%",
            }}
          >
            <div className="text_light_grey_really_small">{t("for")}</div>
            <Menu closeOnSelect={false}>
              <MenuButton
                color="grey"
                style={{
                  backgroundColor: "#E8E8E8",
                  boxSizing: "border-box",
                  paddingLeft: "5%",
                  borderRadius: "5px",
                  position: "relative",
                  height: "80%",
                  marginTop: "1%",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
              >
                <div className="left_container">
                  <div className="text_black_really_small">
                    {menuButtonText
                      ? menuButtonText
                      : t("select_group_student")}
                  </div>
                </div>
                <ChevronDownIcon
                  boxSize="2vh"
                  style={{
                    position: "absolute",
                    top: "25%",
                    right: "2%",
                    color: "#B8B8B8",
                  }}
                />
              </MenuButton>
              <MenuList style={{ maxHeight: "40vh", overflowY: "scroll" }}>
                <MenuGroup
                  title={t("groups")}
                  style={{ fontFamily: "Causten" }}
                >
                  {groups.map((group) => {
                    const groupStudentIds = group.students.map(
                      (student) => student.id
                    );

                    let bundleWithSameStudents: BundleType | undefined =
                      undefined;
                    if (allBundlesofScenario !== null) {
                      bundleWithSameStudents = allBundlesofScenario.find(
                        (bun) => {
                          if (bun.id === bundle?.id) {
                            return false;
                          }
                          const bundleStudentIds = bun.students?.map(
                            (student) => student.id
                          );
                          if (!bundleStudentIds) return false;
                          return arraysEqual(bundleStudentIds, groupStudentIds);
                        }
                      );
                    }

                    return (
                      <MenuItem
                        value={group.id}
                        as={m.div}
                        key={group.id}
                        style={{
                          cursor:
                            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                        }}
                        onClick={
                          bundleWithSameStudents !== undefined
                            ? () => {}
                            : (e) => groupHandler(e, group)
                        }
                        className="professor_activities_scenario_menu_mid_group_item"
                        whileHover={{
                          boxShadow: "0px 0px 10px 1px #BCD1EF",
                          backgroundColor: "#BCD1EF50",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "1vh",
                            fontFamily: "Causten",
                          }}
                        >
                          <GroupAddButton
                            color={
                              group.id === "group1" ? "#6EE6A7" : "#5090F0"
                            }
                            size={28}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              gap: "1vh",
                            }}
                          >
                            {t(group.name)}
                            <Tooltip
                              hasArrow
                              label={
                                bundleWithSameStudents !== undefined
                                  ? t("group_assigned")
                                  : undefined
                              }
                              bg={"#7D7D7D"}
                              placement="top"
                              sx={{
                                fontFamily: "Causten",
                                fontSize: "calc(5px + 0.5vw)",
                                borderRadius: "5px",
                              }}
                              shouldWrapChildren
                            >
                              <Checkbox
                                isChecked={selectedGroup === group}
                                isDisabled={
                                  bundleWithSameStudents !== undefined
                                }
                                size="lg"
                                style={{
                                  cursor:
                                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                                }}
                                onChange={
                                  bundleWithSameStudents !== undefined
                                    ? () => {}
                                    : (e) => groupHandler(e, group)
                                }
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </MenuItem>
                    );
                  })}
                </MenuGroup>
                <MenuDivider />
                <MenuGroup
                  title={t("students")}
                  style={{ fontFamily: "Causten" }}
                >
                  {students.map((student) => {
                    let bundleWithSameStudents: BundleType | undefined =
                      undefined;

                    if (allBundlesofScenario !== null) {
                      bundleWithSameStudents = allBundlesofScenario.find(
                        (bun) => {
                          if (bun.id === bundle?.id) {
                            return false;
                          }
                          const bundleStudentIds = bun.students?.map(
                            (student) => student.id
                          );
                          if (!bundleStudentIds) return false;
                          return arraysEqual(
                            bundleStudentIds,
                            selectedStudents
                          );
                        }
                      );
                    }
                    return (
                      <MenuItem
                        value={student.id}
                        as={m.div}
                        key={student.id}
                        style={{
                          cursor:
                            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                        }}
                        onClick={
                          bundleWithSameStudents !== undefined
                            ? () => {}
                            : (e) => studentHandler(e, student)
                        }
                        className="professor_activities_scenario_menu_mid_group_item"
                        whileHover={{
                          boxShadow: "0px 0px 10px 1px #BCD1EF",
                          backgroundColor: "#BCD1EF50",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "1vh",
                            fontFamily: "Causten",
                          }}
                        >
                          <img
                            src={OnePersonIcon}
                            alt="person"
                            style={{ height: "28px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              gap: "1vh",
                            }}
                          >
                            {student.username}
                            <Checkbox
                              onChange={
                                bundleWithSameStudents !== undefined
                                  ? () => {}
                                  : (e) => studentHandler(e, student)
                              }
                              isChecked={selectedStudents.includes(student.id)}
                              isDisabled={bundleWithSameStudents !== undefined}
                              size="lg"
                              style={{
                                cursor:
                                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                              }}
                            />
                          </div>
                        </div>
                      </MenuItem>
                    );
                  })}
                </MenuGroup>
              </MenuList>
            </Menu>
          </div>
          {/* <FormControl width = 'fit-content' marginLeft="2vw">
                    <FormLabel fontFamily = "Causten" color = "grey" fontSize = "0.8em">{t("weighting")}</FormLabel>
                    <Select
                        name="weighting"
                        options={weightingOptions}
                        key={weightingValue}
                        useBasicStyles
                        size={"sm"}
                        placeholder={t("weighting_placeholder")}
                        onChange={setWeightingValue}
                        value={weightingValue}
                        closeMenuOnSelect={false}
                        selectedOptionStyle='check'
                        chakraStyles={{
                            control: (provided, state) => ({
                                ...provided,
                                width: 'fit-content',
                                fontFamily: 'Causten',
                                fontWeight: 'bold',
                                fontSize: '1em',
                                backgroundColor: "#EEEEEE",
                                boxShadow: state.isFocused ? '0px 0px 10px 2px rgba( 0, 0, 0, 0.25 )' : 'none',
                                cursor: 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                                color: "black",
                                borderRadius: '2.5px',
                            }),
                            menuList: (provided) => ({
                                ...provided,
                                fontFamily: 'Causten',
                            }),
                            dropdownIndicator: (provided, {selectProps}) => ({
                                ...provided,
                                color: "grey",
                                "> svg": {
                                    transitionDuration: "normal",
                                    transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
                                  },
                            }),
                            clearIndicator: (provided) => ({
                                ...provided,
                                color: "red",
                            }),
                        }}
                    />
                </FormControl> */}
          {/* TODO - remove this baby */}
          <div />
          {/* <FormControl width="fit-content" right="0" position={"absolute"}> */}
          {/* <FormLabel fontFamily="Causten" color="grey" fontSize="0.8em">
            {t("dates")}
          </FormLabel> */}
          <Flex width="fit-content" right="0" position={"absolute"}>
            <div
              className="text_black_very_small"
              style={{ marginRight: "2vw" }}
            >
              {t("from")}
            </div>
            <Spacer />
            <DatePicker
              className="scenario_edit_date_picker"
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date: Date) => handleStartDateChange(date)}
              shouldCloseOnSelect
              useWeekdaysShort
              locale={i18n.language}
            />
            <Spacer />
            <div
              className="text_black_very_small"
              style={{ marginRight: "2vw" }}
            >
              {t("to")}
            </div>
            <Spacer />
            <DatePicker
              className="scenario_edit_date_picker"
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date: Date) => handleEndDateChange(date)}
              shouldCloseOnSelect
              useWeekdaysShort
              locale={i18n.language}
            />
          </Flex>
        </LazyMotion>
        {/* </FormControl> */}
      </div>
    );
  };

  const renderMain = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div className="professor_activities_scenario_menu_main" layoutRoot>
          <m.div
            className="professor_activities_scenario_menu_main_scenarios"
            layout="position"
          >
            {renderScenario(scenario)}
          </m.div>
          {renderBottom()}
        </m.div>
      </LazyMotion>
    );
  };

  const renderScenario = (scenario: ModifiedScenarioType) => {
    const selectedSpecificCompetences = specificCompetencesBooleanArray(
      scenario.specific_competences_ids,
      specificCompetences
    );

    const selectedKeyCompetences = globalCompetencesBooleanArray(
      scenario.global_competences_ids,
      globalCompetences
    );
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          layoutRoot
          layout
          className="professor_activities_scenario_menu_main_scenario"
          whileHover={{
            scale: 1.01,
            boxShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
            borderRadius: "10px",
          }}
          transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
        >
          {renderOpenScenario(
            scenario,
            selectedSpecificCompetences,
            selectedKeyCompetences
          )}
        </m.div>
      </LazyMotion>
    );
  };

  const renderOpenScenario = (
    scenario: ModifiedScenarioType,
    selectedSpecificCompetences: boolean[],
    selectedKeyCompetences: boolean[]
  ) => {
    return (
      <m.div
        layout
        className="professor_activities_scenario_menu_main_scenario_open"
      >
        <div className="professor_activities_scenario_menu_main_scenario_open_top_left">
          <img
            className="image"
            src={scenario.image.url}
            alt="scenario_image"
            style={{
              borderRadius: "10px",
              maxHeight: "80px",
            }}
          />
          <div className="professor_activities_scenario_menu_main_scenario_open_top_left_info">
            <div className="professor_activities_scenario_menu_main_scenario_info_top">
              <div className="text_black_very_small">
                <b>{scenario.name}</b>
              </div>
            </div>
            <div className="professor_activities_scenario_menu_main_scenario_info_bottom">
              <div
                className="text_black_super_small"
                style={{ marginTop: "0.5em" }}
              >
                {scenario.description}
              </div>
            </div>
          </div>
        </div>
        <div className="professor_activities_scenario_menu_main_scenario_open_top_right">
          <div className="text_black_very_small_bold">
            {t("basic_knowledge")}
          </div>
          <div
            className="text_black_super_small"
            style={{ marginTop: "0.5em" }}
          >
            {scenario.other_know_hows.length > 0
              ? scenario.main_know_how +
                ", " +
                scenario.other_know_hows.join(", ")
              : scenario.main_know_how}
          </div>
        </div>
        <div className="professor_activities_scenario_menu_main_scenario_open_bottom_left">
          <div className="center_container">
            <m.div
              whileHover={{
                scale: 1.05,
                boxShadow: "0px 0px 10px 2px #5090F0",
              }}
              className="professor_activities_scenario_menu_main_scenario_open_bottom_left_image_container"
              onMouseEnter={() => setShowHoverText("inv")}
              onMouseLeave={() => setShowHoverText("none")}
              onClick={(e) => researchHandler(e, scenario)}
            >
              {showHoverText === "inv" && (
                <div className="professor_activities_scenario_menu_main_scenario_open_bottom_left_image_container_hover center_container">
                  <div className="text_white_medium_bold">
                    {t("see_research")}
                  </div>
                </div>
              )}
              <img className="image" src={placeholderInv} alt="Research" />
            </m.div>
          </div>
          <div className="center_container">
            <m.div
              whileHover={{
                scale: 1.05,
                boxShadow: "0px 0px 10px 2px #5090F0",
              }}
              className="professor_activities_scenario_menu_main_scenario_open_bottom_left_image_container"
              onMouseEnter={() => setShowHoverText("exp")}
              onMouseLeave={() => setShowHoverText("none")}
              onClick={(e) => experimentHandler(e, scenario)}
            >
              {showHoverText === "exp" && (
                <div className="professor_activities_scenario_menu_main_scenario_open_bottom_left_image_container_hover center_container">
                  <div className="text_white_medium_bold">
                    {t("see_experiment")}
                  </div>
                </div>
              )}
              <img className="image" src={placeholderExp} alt="Experiment" />
            </m.div>
          </div>
        </div>
        <div className="professor_activities_scenario_menu_main_scenario_open_bottom_right">
          <div className="professor_activities_scenario_menu_main_scenario_open_bottom_right_top">
            <div className="text_black_very_small_bold">
              {t("evaluation_criteria")}
            </div>
            <div className="professor_activities_scenario_menu_main_scenario_open_bottom_right_top_eval">
              {scenario.evaluation_criteria_ids.map((selectedEvalCritId) => {
                const evaluationCriterion = evaluationCriteria.find(
                  (evalCrit) => evalCrit.id === selectedEvalCritId
                );

                const specificCompetence = specificCompetences.find(
                  (speComp) =>
                    speComp.id === evaluationCriterion?.specific_competence_id
                );

                return (
                  <div className="text_black_super_small">
                    <b>
                      {prettifyCourseAcronyms(scenarioSubject?.acronym) ||
                        "" + specificCompetence?.number ||
                        0 + "." + evaluationCriterion?.number ||
                        0 + ": "}
                    </b>
                    {t(
                      (scenarioSubject?.acronym || "") +
                        (specificCompetence?.number || 0) +
                        "." +
                        (evaluationCriterion?.number || 0) +
                        "_desc",
                      { ns: "evaluation" }
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="professor_activities_scenario_menu_main_scenario_open_bottom_right_bottom">
            <div
              className="professor_activities_scenario_menu_main_scenario_open_bottom_right_bottom_spec"
              style={{
                gridTemplateColumns:
                  "repeat(" + selectedSpecificCompetences.length + ", 1fr)",
              }}
            >
              {selectedSpecificCompetences.map(
                (competence: boolean, index: number) => {
                  return (
                    <div
                      className="text_white_super_small"
                      style={{
                        backgroundColor: competence
                          ? "rgba(41, 47, 76, 1)"
                          : "rgba(41, 47, 76, 0.25)",
                        borderRadius: "100px",
                        maxHeight: "1.3rem",
                        color: "white",
                        padding: "0.1rem 0.5rem 0rem 0.5rem",
                        textAlign: "center",
                        marginRight: "0.5rem",
                      }}
                    >
                      {prettifyCourseAcronyms(scenarioSubject?.acronym) +
                        (index + 1).toString()}
                    </div>
                  );
                }
              )}
            </div>
            <div
              className="professor_activities_scenario_menu_main_scenario_open_bottom_right_bottom_key"
              style={{
                gridTemplateColumns:
                  "repeat(" + simpleGlobalCompetences.length + ", 1fr)",
              }}
            >
              {simpleGlobalCompetences.map((competence, index) => {
                const nonSelectedColor =
                  competence[1].substr(0, competence[1].length - 2) + "0.25)";
                return (
                  <div
                    className="text_white_super_small"
                    style={{
                      backgroundColor: selectedKeyCompetences[index]
                        ? competence[1]
                        : nonSelectedColor,
                      borderRadius: "100px",
                      maxHeight: "1.3rem",
                      color: "white",
                      padding: "0.1rem 0.5rem 0rem 0.5rem",
                      textAlign: "center",
                      marginRight: "0.5rem",
                    }}
                  >
                    {competence[0]}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </m.div>
    );
  };
  const renderBottom = () => {
    // Filter out inactive students
    const activeStudents = bundle?.students?.filter(
      (student) => student.inactive === null
    );
    const activitiesDone = Object.values(scenarioPoints).filter(
      (points) => points > 0
    )?.length;
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          className="professor_activities_scenario_menu_bottom"
          layout="position"
        >
          <div className="professor_activities_scenario_menu_bottom_left top_left_container" />

          <div className="professor_activities_scenario_menu_bottom_right top_right_container">
            {bundle?.students !== undefined && (
              <m.div
                whileHover={{
                  scale: 1.03,
                  boxShadow: "0px 0px 10px 2px #5090F0",
                }}
                className="professor_activities_scenario_menu_bottom_right_background"
                onClick={() => setShowActivityProgress(true)}
              >
                <div className="text_blue_very_small_bold center_container">
                  {t("see_student_progress")}
                </div>
                <div className="professor_activities_scenario_menu_bottom_right_both_numbers">
                  <div className="professor_activities_scenario_menu_bottom_right_number">
                    <div className="text_black_big_bold">
                      {loadingScenario ? <Spinner size="sm" /> : activitiesDone}
                    </div>
                    <div className="text_light_grey_very_small">
                      {t("completed")}
                    </div>
                  </div>
                  <div className="professor_activities_scenario_menu_bottom_right_number">
                    <div className="text_black_big_bold">
                      {loadingBundles ? (
                        <Spinner size="sm" />
                      ) : (
                        activeStudents?.length
                      )}
                    </div>
                    <div className="text_light_grey_very_small">
                      {t("programmed")}
                    </div>
                  </div>
                </div>
              </m.div>
            )}
          </div>
        </m.div>
      </LazyMotion>
    );
  };
  const renderConfirmChanges = (bundle: BundleType) => {
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="professor_sidebar_delete_menu_container">
              <div className="professor_sidebar_delete_menu">
                <div className="professor_sidebar_delete_menu_text">
                  <div className="center_container">
                    <div className="text_black_medium">
                      {t("modify_bundle_confirm")}
                    </div>
                  </div>
                </div>
                <div
                  className="professor_sidebar_delete_menu_close"
                  onClick={() => confirmChangesHandler()}
                >
                  <m.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  />
                </div>

                <div className="professor_sidebar_delete_menu_buttons">
                  <m.div
                    className="professor_sidebar_delete_menu_button_yes"
                    whileHover={{ scale: 1.05 }}
                    onClick={() => saveBundleHandler()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("yes")}</div>
                    </div>
                  </m.div>

                  <m.div
                    className="professor_sidebar_delete_menu_button_no"
                    whileHover={{ scale: 1.05 }}
                    onClick={() => confirmChangesHandler()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("no")}</div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderDeleteMenu = (bundle: BundleType) => {
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="professor_sidebar_delete_menu_container">
              <div className="professor_sidebar_delete_menu">
                <div className="professor_sidebar_delete_menu_text">
                  <div className="center_container">
                    <div className="text_black_medium">
                      {t("delete_bundle_confirm")}
                    </div>
                  </div>
                </div>
                <div
                  className="professor_sidebar_delete_menu_close"
                  onClick={() => deleteMenuHandler()}
                >
                  <m.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  />
                </div>

                <div className="professor_sidebar_delete_menu_buttons">
                  <m.div
                    className="professor_sidebar_delete_menu_button_yes"
                    whileHover={{ scale: 1.05 }}
                    onClick={() => deleteBundleHandler()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("yes")}</div>
                    </div>
                  </m.div>

                  <m.div
                    className="professor_sidebar_delete_menu_button_no"
                    whileHover={{ scale: 1.05 }}
                    onClick={() => deleteMenuHandler()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("no")}</div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderDuplicateScenario = () => {
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="scenario_back_duplicate_menu_container">
              <div className="professor_sidebar_delete_menu">
                <div className="professor_sidebar_delete_menu_text">
                  <div className="scenario_duplicate_menu_main_text">
                    <div className="text_black_small_bold">
                      {t("duplicate_to_edit")}
                    </div>

                    <div className="text_black_small">
                      {t("duplicate_to_edit_subtext")}
                    </div>
                  </div>
                </div>
                <div
                  className="professor_sidebar_delete_menu_close"
                  onClick={() => setDuplicateMenu(null)}
                >
                  <m.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    style={{ maxHeight: "6vh" }}
                  />
                </div>

                <div className="scenario_duplicate_menu_buttons">
                  <m.div
                    className="scenario_duplicate_menu_button_cancel"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => setDuplicateMenu(null)}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <BackCross color={"#FFFFFF"} size={"2.5vh"} />
                      <div className="text_white_very_small_bold">
                        {t("cancel")}
                      </div>
                    </div>
                  </m.div>
                  <m.div
                    className="scenario_duplicate_menu_button_duplicate"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => cloneScenarioHandler(scenario.id)}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <img
                        className="image"
                        src={tick}
                        alt="tick"
                        style={{
                          width: "1.5em",
                          height: "1.5em",
                        }}
                      />
                      <div className="text_white_very_small_bold">
                        {t("duplicate")}
                      </div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderPopUpAddBundle = () => {
    let menuButtonText: string | undefined;
    if (selectedGroup !== null) {
      menuButtonText = t(selectedGroup.name);
    } else if (selectedStudents.length === 1) {
      menuButtonText = students.find(
        (student) => student.id === selectedStudents[0]
      )?.username;
    } else if (selectedStudents.length > 1) {
      menuButtonText = `${selectedStudents.length} ${t(
        "students"
      ).toLowerCase()}`;
    }
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="scenario_back_pop_up_container">
              <div className="professor_sidebar_delete_menu">
                <div className="scenario_pop_up_main_container_grid">
                  <div className="scenario_pop_up_main_content">
                    <div className="left_container">
                      <div className="text_black_medium_bold">
                        {t("submit_situation")}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateRows: "30% 70%",
                        gap: "1vh",
                        height: "100%",
                        width: "70%",
                      }}
                    >
                      <div className="left_container">
                        <div className="text_black_very_small">{t("for")}</div>
                      </div>
                      <Menu closeOnSelect={false}>
                        <MenuButton
                          color="grey"
                          style={{
                            backgroundColor: "#E8E8E8",
                            boxSizing: "border-box",
                            paddingLeft: "5%",
                            borderRadius: "5px",
                            position: "relative",
                            height: "80%",
                            marginTop: "1%",
                            cursor:
                              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                          }}
                        >
                          <div className="left_container">
                            <div className="text_black_really_small">
                              {menuButtonText
                                ? menuButtonText
                                : t("select_group_student")}
                            </div>
                          </div>
                          <ChevronDownIcon
                            boxSize="2vh"
                            style={{
                              position: "absolute",
                              top: "25%",
                              right: "2%",
                              color: "#B8B8B8",
                            }}
                          />
                        </MenuButton>
                        <MenuList
                          style={{ maxHeight: "40vh", overflowY: "scroll" }}
                        >
                          <MenuGroup
                            title={t("groups")}
                            style={{ fontFamily: "Causten" }}
                          >
                            {groups.map((group) => {
                              const groupStudentIds = group.students.map(
                                (student) => student.id
                              );

                              let bundleWithSameStudents:
                                | BundleType
                                | undefined = undefined;
                              if (allBundlesofScenario !== null) {
                                bundleWithSameStudents =
                                  allBundlesofScenario.find((bun) => {
                                    if (bun.id === bundle?.id) {
                                      return false;
                                    }
                                    const bundleStudentIds = bun.students?.map(
                                      (student) => student.id
                                    );
                                    if (!bundleStudentIds) return false;
                                    return arraysEqual(
                                      bundleStudentIds,
                                      groupStudentIds
                                    );
                                  });
                              }

                              return (
                                <MenuItem
                                  value={group.id}
                                  as={m.div}
                                  key={group.id}
                                  style={{
                                    cursor:
                                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                                  }}
                                  onClick={
                                    bundleWithSameStudents !== undefined
                                      ? () => {}
                                      : (e) => groupHandler(e, group)
                                  }
                                  className="professor_activities_scenario_menu_mid_group_item"
                                  whileHover={{
                                    boxShadow: "0px 0px 10px 1px #BCD1EF",
                                    backgroundColor: "#BCD1EF50",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      gap: "1vh",
                                      fontFamily: "Causten",
                                    }}
                                  >
                                    <GroupAddButton
                                      color={
                                        group.id === "group1"
                                          ? "#6EE6A7"
                                          : "#5090F0"
                                      }
                                      size={28}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        gap: "1vh",
                                      }}
                                    >
                                      {t(group.name)}
                                      <Tooltip
                                        hasArrow
                                        label={
                                          bundleWithSameStudents !== undefined
                                            ? t("group_assigned")
                                            : undefined
                                        }
                                        bg={"#7D7D7D"}
                                        placement="top"
                                        sx={{
                                          fontFamily: "Causten",
                                          fontSize: "calc(5px + 0.5vw)",
                                          borderRadius: "5px",
                                        }}
                                        shouldWrapChildren
                                      >
                                        <Checkbox
                                          isChecked={selectedGroup === group}
                                          isDisabled={
                                            bundleWithSameStudents !== undefined
                                          }
                                          size="lg"
                                          style={{
                                            cursor:
                                              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                                          }}
                                          onChange={
                                            bundleWithSameStudents !== undefined
                                              ? () => {}
                                              : (e) => groupHandler(e, group)
                                          }
                                        />
                                      </Tooltip>
                                    </div>
                                  </div>
                                </MenuItem>
                              );
                            })}
                          </MenuGroup>
                          <MenuDivider />
                          <MenuGroup
                            title={t("students")}
                            style={{ fontFamily: "Causten" }}
                          >
                            {students.map((student) => {
                              let bundleWithSameStudents:
                                | BundleType
                                | undefined = undefined;

                              if (allBundlesofScenario !== null) {
                                bundleWithSameStudents =
                                  allBundlesofScenario.find((bun) => {
                                    if (bun.id === bundle?.id) {
                                      return false;
                                    }
                                    const bundleStudentIds = bun.students?.map(
                                      (student) => student.id
                                    );
                                    if (!bundleStudentIds) return false;
                                    return arraysEqual(
                                      bundleStudentIds,
                                      selectedStudents
                                    );
                                  });
                              }
                              return (
                                <MenuItem
                                  value={student.id}
                                  as={m.div}
                                  key={student.id}
                                  style={{
                                    cursor:
                                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                                  }}
                                  onClick={
                                    bundleWithSameStudents !== undefined
                                      ? () => {}
                                      : (e) => studentHandler(e, student)
                                  }
                                  className="professor_activities_scenario_menu_mid_group_item"
                                  whileHover={{
                                    boxShadow: "0px 0px 10px 1px #BCD1EF",
                                    backgroundColor: "#BCD1EF50",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      gap: "1vh",
                                      fontFamily: "Causten",
                                    }}
                                  >
                                    <img
                                      src={OnePersonIcon}
                                      alt="person"
                                      style={{ height: "28px" }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        gap: "1vh",
                                      }}
                                    >
                                      {student.username}
                                      <Checkbox
                                        onChange={
                                          bundleWithSameStudents !== undefined
                                            ? () => {}
                                            : (e) => studentHandler(e, student)
                                        }
                                        isChecked={selectedStudents.includes(
                                          student.id
                                        )}
                                        isDisabled={
                                          bundleWithSameStudents !== undefined
                                        }
                                        size="lg"
                                        style={{
                                          cursor:
                                            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                                        }}
                                      />
                                    </div>
                                  </div>
                                </MenuItem>
                              );
                            })}
                          </MenuGroup>
                        </MenuList>
                      </Menu>
                    </div>

                    <Flex
                      width="100%"
                      height="100%"
                      position={"relative"}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="text_black_very_small">{t("from")}</div>
                      <DatePicker
                        className="scenario_edit_date_picker"
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={(date: Date) => handleStartDateChange(date)}
                        shouldCloseOnSelect
                        useWeekdaysShort
                        locale={i18n.language}
                      />
                      <div className="text_black_very_small">{t("to")}</div>
                      <DatePicker
                        className="scenario_edit_date_picker"
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        onChange={(date: Date) => handleEndDateChange(date)}
                        shouldCloseOnSelect
                        useWeekdaysShort
                        locale={i18n.language}
                      />
                    </Flex>
                  </div>
                </div>
                <div
                  className="professor_sidebar_delete_menu_close"
                  onClick={() => popUpAddBundleHandler()}
                >
                  <m.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    style={{ maxHeight: "6vh" }}
                  />
                </div>
                <div className="center_container">
                  <m.div
                    className="scenario_pop_up_button"
                    whileHover={{ scale: 1.1 }}
                    onClick={
                      bundle === null
                        ? () => saveBundleHandler()
                        : () => confirmChangesHandler(bundle)
                    }
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_very_small_bold">
                        {t("assign_situation")}
                      </div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderButtons = () => {
    if (bundle !== null) {
      return (
        <LazyMotion features={loadFeatures}>
          <div className="professor_scenario_menu_buttons_container">
            <m.div
              className="professor_scenario_menu_change_bundle_button"
              style={{
                backgroundColor: isToastDisabled ? "#B8B8B8" : "#6EE6A7",
              }}
              whileHover={{
                scale: isToastDisabled ? 1 : 1.1,
                boxShadow: isToastDisabled
                  ? "#B8B8B8 0px 0px 10px 2px"
                  : "#6EE6A7 0px 0px 10px 2px",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 20 }}
              onClick={() => confirmChangesHandler(bundle)}
              // onClick={() => popUpAddBundleHandler()}
            >
              <img
                className="image"
                src={tick}
                alt="tick"
                style={{
                  width: "1.5em",
                  height: "1.5em",
                }}
              />
              <div
                className="text_white_very_small_bold"
                style={{ marginLeft: "0.5em" }}
              >
                {t("confirm_changes")}
              </div>
            </m.div>
            <m.div
              className="professor_scenario_menu_delete_bundle_button"
              style={{
                backgroundColor: isToastDisabled ? "#B8B8B8" : "#F06D50",
              }}
              whileHover={{
                scale: isToastDisabled ? 1 : 1.1,
                boxShadow: isToastDisabled
                  ? "#B8B8B8 0px 0px 10px 2px"
                  : "#F06D50 0px 0px 10px 2px",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 20 }}
              onClick={() => deleteMenuHandler(bundle)}
            >
              <img
                className="image"
                src={bin}
                alt="bin"
                style={{
                  width: "1.5em",
                  height: "1.5em",
                }}
              />
              <div
                className="text_white_very_small_bold"
                style={{ marginLeft: "0.5em" }}
              >
                {t("delete_unit")}
              </div>
            </m.div>
          </div>
        </LazyMotion>
      );
    } else {
      return (
        <LazyMotion features={loadFeatures}>
          <div className="professor_scenario_menu_buttons_container">
            <m.div
              className="professor_scenario_menu_edit_change_bundle_button"
              whileHover={{ scale: 1.1, boxShadow: "#5090F0 0px 0px 10px 2px" }}
              transition={{ type: "spring", stiffness: 400, damping: 20 }}
              onClick={() => editScenarioHandler()}
            >
              <img
                className="image"
                src={edit}
                alt="edit"
                style={{
                  width: "1.5em",
                  height: "1.5em",
                }}
              />
              <div
                className="text_white_very_small_bold"
                style={{ marginLeft: "0.5em" }}
              >
                {scenario?.owner_id === loginState.id
                  ? t("edit_activity")
                  : t("copy_and_edit_activity")}
              </div>
            </m.div>
            <m.div
              className="professor_scenario_menu_add_bundle_button"
              whileHover={{ scale: 1.1, boxShadow: "#6EE6A7 0px 0px 10px 2px" }}
              transition={{ type: "spring", stiffness: 400, damping: 20 }}
              // onClick={() => saveBundleHandler()}
              onClick={() => popUpAddBundleHandler()}
            >
              <img
                className="image"
                src={tick}
                alt="tick"
                style={{
                  width: "1.5em",
                  height: "1.5em",
                }}
              />
              <div
                className="text_white_very_small_bold"
                style={{ marginLeft: "0.5em" }}
              >
                {t("add_scenario")}
              </div>
            </m.div>
          </div>
        </LazyMotion>
      );
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        padding: "4% 2% 2% 2%",
      }}
    >
      {
        <div
          className={
            loading ? "center_container" : "professor_activities_scenario_menu"
          }
          style={{
            gridTemplateRows: bundle === null ? "15% 85%" : "10% 10% 80%",
          }}
        >
          {loading && <Loading />}
          {!loading && renderTop()}
          {!loading && bundle !== null && renderMiddle()}
          {!loading && renderButtons()}
          {!loading && !showActivityProgress && renderMain()}
          {!loading && !showActivityProgress && dateError !== null && (
            <Alert text={dateError} handleAlert={() => dateErrorHandler()} />
          )}
          {!loading && showActivityProgress && (
            <ActivityProgress
              scenarios={[scenario]}
              scenarioPoints={scenarioPoints}
              scenarioId={scenario.id}
              courseId={scenario.course_id}
              bundle={bundle}
            />
          )}
        </div>
      }
      {/* {!loading && openScenarioEditMenu && (
        <ScenarioEditMenu
          scenario={scenario}
          deleteOnExit={props.deleteOnExit}
          deleteScenarioBackHandler={() => {
            props.backHandler("none");
            setOpenScenarioEditMenu(false);
          }}
        />
      )} */}

      {!_.isNull(deleteMenu) && renderDeleteMenu(deleteMenu)}
      {!_.isNull(changeBundle) && renderConfirmChanges(changeBundle)}
      {!_.isNull(duplicateMenu) && renderDuplicateScenario()}
      {popUpAddBundle && renderPopUpAddBundle()}
      {alertScenarioAlreadyCopied === true && (
        <Alert
          handleAlert={() => alertScenarioCopiedHandler()}
          text={t("scenario_copied_error")}
        />
      )}
      {/* {alertBundlesSameStudents === true && (
        <Alert
          handleAlert={() => alertBundleSameStudentsHandler()}
          text={t("bundle_same_students_error")}
        />
      )} */}
      {alertChooseGroup === true && (
        <Alert
          handleAlert={() => alertChooseGroupHandler()}
          text={t("select_group_before_confirm")}
        />
      )}
    </div>
  );
};

export default ScenarioMenu;
