import env from "../../config/env.json";
import { ClassType } from "../user/class";

export interface ThemeType {
    id: string,
    name: string,
    objectives: string,
    metric: string[],
    description: string
}

export const GetThemesAPI = async (): Promise<ThemeType[]> => {
    var req = await fetch(env.backend_host + "/theme/all", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || []
    return data
}

export const SetClassThemeAPI = async (class_id: string, theme_id: string): Promise<ClassType> => {
    var req = await fetch(env.backend_host + "/theme/" + theme_id + "/class/" + class_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "mode": "cors",
        "credentials": "include",
    });
    var data = await req.json();

    data = data || false
    return data
}
