import env from "../../config/env.json";
import { AnimationType } from "../content/unit";
import { BiomeNameType } from "./plots";

export interface BiomeType {
    id: string,
    name: BiomeNameType,
    color: string,
    contrary_biome_id: string,
    adverse_biome: {
        id: string,
        name: string,
    }[],
    restore_video_id: string;
    restore_video: AnimationType;
}

export const GetAllBiomesAPI = async (): Promise<BiomeType[]> => {
    var req = await fetch(env.backend_host + "/biome/all", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}
