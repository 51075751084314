import env from "../../config/env.json";
import { AnimationType, ImageType } from "../content/unit";
import { StudentEutonType } from "../progress/student_euton.ts";
import { BiomeType } from "./biomes";
import { AssetType } from "./house_upgrades";

export interface EutonType {
  id: string;
  name: string;
  item_name: string;
  description: string;
  tier: number;
  sound: string;
  image_id: string;
  image: ImageType;
  biome_id: string;
  biome: BiomeType;
  item_image_id: string;
  item_image: ImageType;
  image_locked_id: string;
  image_locked: ImageType;
  threed_video_id: string;
  threed_video: AnimationType;
  card_image: ImageType;
  card_image_id: string;
  unlock_video_id: string;
  unlock_video: AnimationType;
  asset: AssetType[];
  student_euton: StudentEutonType[];
}

export const GetAllEutonsAPI = async (): Promise<EutonType[]> => {
  var req = await fetch(env.backend_host + "/euton/all", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
    mode: "cors",
    credentials: "include",
  });
  var data = await req.json();

  data = data || null;
  return data;
};
