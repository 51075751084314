import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import commonEN from './locales/en/common.json';
import commonES from './locales/es/common.json';
import commonDE from './locales/de/common.json';
import commonVAL from './locales/val/common.json';
import commonCAT from './locales/cat/common.json';


import evaluationEN from './locales/en/evaluation.json';
import evaluationES from './locales/es/evaluation.json';
import evaluationDE from './locales/de/evaluation.json';
import evaluationVAL from './locales/val/evaluation.json';
import evaluationCAT from './locales/cat/evaluation.json';

import pdfEN from './locales/en/pdf.json';
import pdfES from './locales/es/pdf.json';
import pdfDE from './locales/de/pdf.json';
import pdfVAL from './locales/val/pdf.json';
import pdfCAT from './locales/cat/pdf.json';

import eutonsEN from './locales/en/eutons.json';
import eutonsES from './locales/es/eutons.json';
import eutonsDE from './locales/de/eutons.json';
import eutonsVAL from './locales/val/eutons.json';
import eutonsCAT from './locales/cat/eutons.json';

// the translations
const resources = {
  en: {
    common: commonEN,
    evaluation: evaluationEN,
    pdf: pdfEN,
    eutons: eutonsEN
  },
  es: {
    common: commonES,
    evaluation: evaluationES,
    pdf: pdfES,
    eutons: eutonsES
  },
  de: {
    common: commonDE,
    evaluation: evaluationDE,
    pdf: pdfDE,
    eutons: eutonsDE
  },
  val: {
    common: commonVAL,
    evaluation: evaluationVAL,
    pdf: pdfVAL,
    eutons: eutonsVAL
  },
  cat: {
    common: commonCAT,
    evaluation: evaluationCAT,
    pdf: pdfCAT,
    eutons: eutonsCAT
  }
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: ['common', 'evaluation', 'pdf', 'eutons'],
    defaultNS: 'common',
    fallbackLng: "es", // use es if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;