import env from "../../config/env.json";
import { BundleType } from "../user/class.ts";
import { ScenarioType } from "./unit.ts";

export type ModifiedScenarioType = ScenarioType & {
  specific_competences_ids: string[];
  global_competences_ids: string[];
};

export const GetAllScenariosAPI = async (): Promise<ModifiedScenarioType[]> => {
  var req = await fetch(env.backend_host + "/scenario/all", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
    mode: "cors",
    credentials: "include",
  });
  var data = req.json();
  return data;
};

export const AllBundlesofScenarioAPI = async (
  scenario_id: string,
  class_id: string
): Promise<BundleType[] | null> => {
  const req = await fetch(
    env.backend_host + "/scenario/" + scenario_id + "/bundles/" + class_id,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
      mode: "cors",
      credentials: "include",
    }
  );

  var data = await req.json();

  data = data || null;
  return data;
};

export const UpdateScenarioAPI = async (
  id: string,
  scenario: ScenarioType
): Promise<ModifiedScenarioType> => {
  var req = await fetch(env.backend_host + "/scenario/" + id, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    body: JSON.stringify(scenario),
    mode: "cors",
    credentials: "include",
  });
  var status = req.status;
  // TODO - handle errors??
  var data = req.json();
  return data;
};

export const DeleteScenarioAPI = async (id: string): Promise<void> => {
  var req = await fetch(env.backend_host + "/scenario/" + id, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });
  var data = req.status;
};

export const PermanentlyDeleteScenarioAPI = async (
  id: string
): Promise<void> => {
  var req = await fetch(env.backend_host + "/scenario/permanent/" + id, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });
  var data = req.status;
};

export const CreateScenarioAPI = async (
  regulation_id: string
): Promise<ModifiedScenarioType> => {
  var req = await fetch(env.backend_host + "/scenario/", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    body: JSON.stringify({ regulation_id }),
    mode: "cors",
    credentials: "include",
  });
  var data = req.json();

  return data;
};

export const GetScenarioAPI = async (
  scenario_id: string
): Promise<ModifiedScenarioType> => {
  var req = await fetch(env.backend_host + "/scenario/" + scenario_id, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
    mode: "cors",
    credentials: "include",
  });
  var data = await req.json();

  if (
    data.specfic_competences_ids === undefined &&
    data.global_competences_ids === undefined
  ) {
    data.specific_competences_ids = [];
    data.global_competences_ids = [];
  }

  return data;
};

export const CloneScenarioAPI = async (
  existing_scenario_id: string
): Promise<ModifiedScenarioType | null> => {
  var req = await fetch(
    env.backend_host + "/scenario/" + existing_scenario_id + "/clone",
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      mode: "cors",
      credentials: "include",
    }
  );

  var data = await req.json();

  data = data || null;

  return data;
};
