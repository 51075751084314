import env from "../../config/env.json";
import { ImageType } from "../content/unit";

export type GlobalCompetencePoints = { [key: string]: number };

// Very specific to the global competence scenario, might have to get changed
export interface GlobalCompetenceScenarioType {
  id: string;
  name: string;
  image: ImageType;
  course_id: string;
  main_know_how: string;
}

export const GetStudentGlobalCompetencesAPI = async (
  id: string
): Promise<GlobalCompetencePoints> => {
  var req = await fetch(
    env.backend_host + "/student/" + id + "/global_competences/",
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
      mode: "cors",
      credentials: "include",
    }
  );
  var data = await req.json();

  data = data || null;
  return data;
};

export const GetClassGlobalCompetencesAPI = async (
  id: string
): Promise<GlobalCompetencePoints> => {
  var req = await fetch(
    env.backend_host + "/class/" + id + "/global_competences/",
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
      mode: "cors",
      credentials: "include",
    }
  );
  var data = await req.json();

  data = data || null;
  return data;
};

export const GetGlobalCompetenceScenariosAPI = async (
  student_id: string,
  acronym: string
): Promise<GlobalCompetenceScenarioType[]> => {
  var req = await fetch(
    env.backend_host +
      "/student/" +
      student_id +
      "/global_competence/" +
      acronym +
      "/scenarios",
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
      mode: "cors",
      credentials: "include",
    }
  );
  var data = await req.json();

  data = data || null;
  return data;
};
