import "./BusinessView.css";
import { useState, useEffect } from "react";
import {
  BusinessTeacherType,
  GetAllLoginsAPI,
  GetAllTeachersAPI,
  LoginsType,
} from "../../../_newapios/user/teacher";
import { LazyMotion, m } from "framer-motion";
import Loading from "../../loading_screen/LoadingDots.tsx";
import BackArrow from "../../../assets/exercises/back_arrow.tsx";
import {
  BusinessSchoolType,
  CreateSchoolAPI,
  GetAllSchoolsAPI,
  SchoolType,
} from "../../../_newapios/user/school.ts";

import Plus from "../../../assets/professor/plus.tsx";
import Close from "../../../assets/professor/close.tsx";
import AccountSchool from "../../../assets/professor/account-school.tsx";
import AccountCheck from "../../../assets/professor/account-check.tsx";
import BookOpen from "../../../assets/professor/book-open-blank-variant-outline.tsx";
import { Input } from "@chakra-ui/react";
import _ from "lodash";
import { toast } from "../../../index.tsx";
import { setCookie } from "../../../utils/general.ts";

export type viewTypes =
  | "free_teachers"
  | "licenses"
  | "paid_teachers"
  | "schools";

const BusinessView = ({ ...props }) => {
  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const views = ["schools", "free_teachers", "paid_teachers"];

  const [logins, setLogins] = useState<LoginsType[]>([]);
  const [teachers, setTeachers] = useState<BusinessTeacherType[]>([]);
  const [selectedTeacher, setSelectedTeacher] =
    useState<BusinessTeacherType | null>(null);
  const [schools, setSchools] = useState<BusinessSchoolType[]>([]);
  const [selectedSchool, setSelectedSchool] =
    useState<BusinessSchoolType | null>(null);
  const [view, setView] = useState<string>("loading");
  const [openCreateSchool, setOpenCreateSchool] = useState<boolean>(false);
  const [newSchoolName, setNewSchoolName] = useState<string>("");
  const [newSchoolTown, setNewSchoolTown] = useState<string>("");
  const [newSchoolError, setNewSchoolError] = useState<boolean>(false);

  useEffect(() => {
    setView("loading");
    loadData();
  }, []);

  const loadData = async () => {
    const promises = [
      GetAllTeachersAPI().then((res) => setTeachers(res)),
      GetAllLoginsAPI().then((res) => setLogins(res)),
      GetAllSchoolsAPI().then((res) => setSchools(orderSchools(res))),
    ];
    Promise.all(promises).then((res) => {
      setView("schools");
    });
  };

  const orderSchools = (schools: BusinessSchoolType[]) => {
    return _.sortBy(schools, (school) => {
      const lastLogin = calcSchoolLastLogin(school);
      console.log(new Date().getTime() - new Date(lastLogin).getTime());
      return lastLogin === "Nunca"
        ? -1
        : new Date().getTime() - new Date(lastLogin).getTime();
    });
  };

  const calcSchoolLastLogin = (school: BusinessSchoolType) => {
    let timestamp =
      school.teachers.length > 0
        ? school.teachers[0].back_office_logins.length > 0
          ? school.teachers[0].back_office_logins[0].timestamp
          : ""
        : "";
    let lastLogin = timestamp !== "" ? new Date(timestamp) : null;
    if (lastLogin === null) {
      return "Nunca";
    }
    school.teachers.forEach((teacher) => {
      teacher.back_office_logins.forEach((login) => {
        const date = new Date(login.timestamp);
        if (date > (lastLogin as Date)) {
          lastLogin = date;
        }
      });
    });

    return lastLogin.toDateString();
  };

  const newSchoolNameHandler = (e: any) => {
    setNewSchoolName(e.target.value);
  };

  const newSchoolTownHandler = (e: any) => {
    setNewSchoolTown(e.target.value);
  };

  const createSchoolHandler = async () => {
    if (newSchoolName !== "" && newSchoolTown !== "") {
      console.log(
        "Creating school with name: " +
          newSchoolName +
          " and town: " +
          newSchoolTown
      );
      const status = await CreateSchoolAPI(newSchoolName, newSchoolTown);
      console.log("Status: " + status);
      if (status === 409) {
        toast({
          title: "Error al crear la escuela",
          description: "Ya existe una escuela con ese nombre, estás empanao",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setNewSchoolError(true);
        return;
      }
      if (status !== 200) {
        toast({
          title: "Error al crear la escuela",
          description: "Ha ocurrido un error al crear la escuela",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
      if (status === 200) {
        toast({
          title: "Escuela creada",
          description: "La escuela ha sido creada con éxito",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
      setNewSchoolName("");
      setNewSchoolTown("");
      setOpenCreateSchool(false);
      loadData();
    } else {
      setNewSchoolError(true);
      toast({
        title: "Error al crear la escuela",
        description: "Rellena todos los campos, mendrugo",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const renderCreateSchoolButton = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          className="business_view_create_school"
          whileHover={{ scale: 1.1 }}
          onClick={() => setOpenCreateSchool(true)}
        >
          <div className="text_black_small_bold">Crear escuela</div>
        </m.div>
      </LazyMotion>
    );
  };

  const renderLegends = () => {
    return (
      <div className="business_view_legends">
        <div className="business_view_legend">
          <AccountCheck size={"20px"} color={"grey"} />
          <div className="text_black_very_small">Cuenta verificada</div>
        </div>
        <div className="business_view_legend">
          <Plus size={"20px"} color={"grey"} />
          <div className="text_black_very_small">Clase creada</div>
        </div>
        <div className="business_view_legend">
          <AccountSchool size={"20px"} color={"grey"} />
          <div className="text_black_very_small">Estudiantes</div>
        </div>
        <div className="business_view_legend">
          <BookOpen size={"20px"} color={"grey"} />
          <div className="text_black_very_small">Situaciones completadas</div>
        </div>
      </div>
    );
  };

  const renderLogins = (loginList: LoginsType[]) => {
    return loginList.map((login) => {
      const date = new Date(login.timestamp);
      return (
        <div key={login.id} className="business_view_login">
          <div className="text_black_small_bold">{login.teacher.fullname}</div>
          <div className="text_black_small">{date.toLocaleTimeString()}</div>
          <div className="text_black_small">{date.toLocaleDateString()}</div>
        </div>
      );
    });
  };

  const renderTeachers = (teacherList: BusinessTeacherType[]) => {
    return teacherList.map((teacher) => {
      const lastLogin = new Date(teacher.back_office_logins[0]?.timestamp);
      const now = new Date();
      const dropped =
        now.getTime() - lastLogin.getTime() > 1000 * 60 * 60 * 24 * 25;
      const nurturing =
        now.getTime() - lastLogin.getTime() > 1000 * 60 * 60 * 24 * 90;

      const verified = teacher.verification_code === null;
      const classCreated = teacher.classes.length > 0;
      const hasStudents = teacher.classes.some((cl) => cl.students.length > 1);
      const hasCompletedScenarios = teacher.classes.some((cl) =>
        cl.students.some((st) => st._count.scenario_progress > 1)
      );

      return (
        <LazyMotion features={loadFeatures} key={teacher.id}>
          <m.div
            key={teacher.id}
            className="business_view_teacher"
            onClick={() => {
              setSelectedTeacher(teacher);
              setView("logins");
            }}
            style={{
              backgroundColor: nurturing ? "#FA5F55" : dropped ? "#FFFF8F" : "",
            }}
            whileHover={{ scale: 1.01 }}
            whileInView={{
              boxShadow: nurturing
                ? "0px 0px 5px 0px #FA5F55"
                : dropped
                ? "0px 0px 5px 0px #FFFF8F"
                : "",
            }}
          >
            <div className="text_black_small_bold">{teacher.fullname}</div>
            <div className="business_view_teacher_icons">
              <AccountCheck
                size={"20px"}
                color={verified ? "#6EE6A7" : "grey"}
              />
              <Plus size={"20px"} color={classCreated ? "#6EE6A7" : "grey"} />
              <AccountSchool
                size={"20px"}
                color={hasStudents ? "#6EE6A7" : "grey"}
              />
              <BookOpen
                size={"20px"}
                color={hasCompletedScenarios ? "#6EE6A7" : "grey"}
              />
            </div>
            <div className="text_black_small">{teacher.school.name}</div>
            <div className="text_black_small">
              {lastLogin.toLocaleDateString()}
            </div>
          </m.div>
        </LazyMotion>
      );
    });
  };

  const renderSchools = () => {
    return schools.map((school) => {
      return (
        <LazyMotion features={loadFeatures} key={school.id}>
          <m.div
            key={school.id}
            className="business_view_teacher"
            onClick={() => {
              setSelectedSchool(school);
            }}
            whileHover={{ scale: 1.01 }}
          >
            <div className="text_black_small_bold">{school.name}</div>
            <div className="text_black_small">
              {school.teachers.length} profesores
            </div>
            <div className="text_black_small" style={{ userSelect: "text" }}>
              Pin: {school.pin}
            </div>
            <div className="text_black_small">
              {calcSchoolLastLogin(school)}
            </div>
          </m.div>
        </LazyMotion>
      );
    });
  };

  const renderButtons = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <div className="business_view_buttons">
          {/* <div className="business_view_button" onClick={() => setView("licenses")}>Licenses</div> */}
          {views.map((v) => {
            return (
              <m.div
                key={v}
                className="business_view_button"
                onClick={() => {
                  setView(v);
                  setSelectedTeacher(null);
                  setSelectedSchool(null);
                }}
                whileHover={{ scale: 1.1 }}
                style={{ backgroundColor: view === v ? "#5090F0" : "" }}
              >
                <div className="text_black_small_bold">{v}</div>
              </m.div>
            );
          })}
        </div>
      </LazyMotion>
    );
  };

  const renderCreateSchool = () => {
    return (
      <div className="business_view_create_school_background">
        <LazyMotion features={loadFeatures}>
          <div className="business_view_create_school_container">
            <m.div
              className="business_view_create_school_close"
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 300, damping: 15 }}
              onClick={() => setOpenCreateSchool(false)}
            >
              <Close size={"30px"} color={"#5090F0"} />
            </m.div>
            <div className="center_container">
              <Input
                variant="outline"
                w={"50%"}
                bgColor={"#E2EEFF"}
                as={m.input}
                whileHover={{ scale: 1.05 }}
                boxShadow={"0px 0px 5px 2px #5090F0"}
                placeholder="Nombre de la escuela"
                onChange={newSchoolNameHandler}
                value={newSchoolName}
                isInvalid={newSchoolError}
              />
            </div>
            <div className="center_container">
              <Input
                variant="outline"
                w={"50%"}
                bgColor={"#E2EEFF"}
                as={m.input}
                whileHover={{ scale: 1.05 }}
                boxShadow={"0px 0px 5px 2px #5090F0"}
                placeholder="Población de la escuela"
                onChange={newSchoolTownHandler}
                value={newSchoolTown}
                isInvalid={newSchoolError}
              />
            </div>
            <div className="center_container">
              <m.div
                className="business_view_create_school_button"
                onClick={() => createSchoolHandler()}
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 300, damping: 15 }}
              >
                Crear
              </m.div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  return (
    <div className="business_view_main">
      <div className="business_view_header">
        {renderCreateSchoolButton()}
        <img
          src="https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/ZynergicLogo.png"
          alt="logo"
          style={{
            position: "relative",
            height: "90%",
            borderRadius: "5px",
          }}
        />
        <div className="text_black_big_bold">Viva Zynergic</div>
        {renderLegends()}
      </div>
      {renderButtons()}
      {openCreateSchool && renderCreateSchool()}
      <div className="business_view_content">
        {view === "logins" && (
          <div className="business_view_logins">
            {selectedTeacher && (
              <div className="business_view_teacher_selected">
                <div
                  className="left_container"
                  style={{
                    maxWidth: "fit-content",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  onClick={() => {
                    setView(
                      selectedSchool === null
                        ? selectedTeacher.school.user_license.some(
                            (l) => l.license.name === "Free"
                          )
                          ? "free_teachers"
                          : "paid_teachers"
                        : "schools"
                    );
                    setSelectedTeacher(null);
                  }}
                >
                  {<BackArrow color={"black"} size={"35px"} />}
                  <div className="text_black_small_bold">
                    {selectedTeacher.fullname}
                  </div>
                </div>
                <div
                  className="text_black_small"
                  style={{ userSelect: "text" }}
                >
                  {selectedTeacher.username}
                </div>
                <div className="text_black_small">
                  {selectedTeacher.school.name}
                </div>
                <LazyMotion features={loadFeatures}>
                  <m.div
                    className="business_view_impersonate_button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCookie("impersonating", selectedTeacher.id, 30);
                      window.location.reload();
                    }}
                    whileHover={{ scale: 1.1 }}
                    transition={{ type: "spring", stiffness: 300, damping: 15 }}
                  >
                    <div className="text_white_very_small">
                      {"Entrar como este profesor"}
                    </div>
                  </m.div>
                </LazyMotion>
              </div>
            )}
            {renderLogins(
              logins.filter((l) => l.teacher.id === selectedTeacher?.id)
            )}
          </div>
        )}
        {view === "licenses" && <div className="business_view_teachers"></div>}
        {view === "paid_teachers" && (
          <div className="business_view_teachers">
            {renderTeachers(
              teachers.filter((t) =>
                t.school.user_license.some((l) => l.license.name === "Ultimate")
              )
            )}
          </div>
        )}
        {view === "free_teachers" && (
          <div className="business_view_teachers">
            {renderTeachers(
              teachers.filter((t) =>
                t.school.user_license.some((l) => l.license.name === "Free")
              )
            )}
          </div>
        )}
        {view === "schools" && selectedSchool === null && (
          <div className="business_view_teachers">{renderSchools()}</div>
        )}
        {view === "schools" && selectedSchool !== null && (
          <div className="business_view_teachers">
            <div className="business_view_teacher_selected">
              <div
                className="left_container"
                style={{
                  maxWidth: "fit-content",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={() => {
                  setView("schools");
                  setSelectedSchool(null);
                }}
              >
                {<BackArrow color={"black"} size={"35px"} />}
                <div className="text_black_small_bold">
                  {selectedSchool.name}
                </div>
              </div>
              <div className="text_black_small">
                {selectedSchool.town ? selectedSchool.town : "No disponible"}
              </div>
              <div className="text_black_small" style={{ userSelect: "text" }}>
                Pin: {selectedSchool.pin}
              </div>
            </div>
            {renderTeachers(
              teachers.filter((t) => t.school.id === selectedSchool.id)
            )}
          </div>
        )}
        {view === "loading" && <Loading />}
      </div>
    </div>
  );
};

export default BusinessView;
