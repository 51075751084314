import env from "../../config/env.json";
import { AnimationType, ImageType } from "../content/unit";
import { BiomeType } from "./biomes";
import { AssetType } from "./house_upgrades";

export interface ExplorerType {
    id: string,
    name: string,
    description: string,
    destruction: number,
    passive: string,
    image_id: string,
    image: ImageType,
    asset_id: string,
    asset: AssetType,
}

export const GetAllExplorersAPI = async (): Promise<ExplorerType[]> => {
    var req = await fetch(env.backend_host + "/explorer/all", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}
