import env from "../../config/env.json";
import { BundleType, ModifiedBundleType } from "../user/class";
import { UnitType } from "./unit";

export const CreateBundleAPI = async (
  bundle: BundleType
): Promise<BundleType> => {
  var req = await fetch(env.backend_host + "/bundle", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    body: JSON.stringify(bundle),
    mode: "cors",
    credentials: "include",
  });
  var data = await req.json();

  return data;
};

export const UpdateBundleAPI = async (
  bundle: BundleType
): Promise<BundleType> => {
  var req = await fetch(env.backend_host + "/bundle/" + bundle.id, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    body: JSON.stringify(bundle),
    mode: "cors",
    credentials: "include",
  });
  var data = await req.json();

  return data;
};

export const DeleteBundleAPI = async (id: string): Promise<void> => {
  var req = await fetch(env.backend_host + "/bundle/" + id, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });
};

export const GetStudentBundlesAPI = async (
  id: string
): Promise<ModifiedBundleType[]> => {
  var req = await fetch(env.backend_host + "/student/" + id + "/bundles", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
    mode: "cors",
    credentials: "include",
  });
  var data = await req.json();

  return data;
};
